import React from 'react';

class WhatWeDo extends React.Component{
render()
{
return (
<div>

<section class="repair-area pt-100 pb-70 jarallax" data-jarallax='{"speed": 0.3}' id="Services">
         <div class="container">
            <div class="section-title white-title">
               <span>Unleash the Power of Rental</span>
               <h2>Our Services</h2>
               {/* <p>We are providing laptop and Desktop for Rental basis with 100% hardware support.</p> */}
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="single-repair">
                      <div class="row">
                        <div class="col-lg-4 col-sm-6">
                        <div class="repair-content">
                           <img  src="./assets/images/desktop.png" alt="Computer Rental"/>
                           <h3>Desktop Rental</h3>
                           <p>Got a temporary project or need a specific model?
Skip the big investment and rent desktops that perfectly match your
requirements. Choose from a diverse range of brands and
configurations, all meticulously maintained and ready to roll.</p>
                           {/* <a href="#">1</a> */}
                           </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                           <div class="repair-content">
                           <img  src="./assets/images/laptop.png" alt="Laptop Rental"/>
                           <h3> Laptop Rental</h3>
                           <p>Whether you're hosting a conference, training
session, or simply need a mobile workstation, our diverse laptop
selection has you covered. Ditch the purchase anxiety and rent
reliable, high-performance laptops perfect for any professional or
personal need.</p>
                           </div>
                        </div>

                        
                        <div class="col-lg-4 col-sm-6">
                        <div class="repair-content">
                           <img  src="./assets/images/hardware.png" alt="Hardware Support"/>
                           <h3>24/7 Hardware Support</h3>
                           <p>Hardware hiccups never happen at a
convenient time. That's why we offer comprehensive 24/7 support,
ensuring your rented equipment keeps humming smoothly. Rest
assured, our techs are always on call to resolve any issues promptly.</p>                     
                           
                           </div>
                        </div>
                     </div>
                  </div>
               </div> 
               <div class="col-lg-12">
                  <div class="single-repair">
                     <div class="row">
                        
                     <div class="col-lg-4 col-sm-6">
                           <div class="repair-content">
                           <img  src="./assets/images/delivery.png" alt="Delivery Support"/>
                           <h3>Door-Step Delivery:</h3>
                           <p>Forget the hassle of pick-ups and drop-offs.
We bring the tech to you! Our convenient door-step delivery service
ensures seamless equipment access, saving you valuable time and
energy.
</p>                     
                           </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                        <div class="repair-content">
                           <img  src="./assets/images/hardware.png" alt="Hardware Support"/>
                           <h3>Beyond Workstations</h3>
                           <p>We're not just your laptop and desktop rental
source. We offer a comprehensive range of IT equipment for any
occasion, including:
</p>                     
                           
                           </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                           <div class="repair-content">
                           <img  src="./assets/images/delivery.png" alt="Delivery Support"/>
                           <h3> Printers and Scanners:</h3>
                           <p> Keep your workflow running smoothly with
high-quality printing and scanning solutions.
</p>                     
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            
            

            </div>
         </div>
      </section>

</div>
    
);
}
}
export default WhatWeDo;