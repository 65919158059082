import React from 'react';

class WhatWeDo extends React.Component{
render()
{
return (
<div>

<section class="testimonial-area ptb-100" id="Testimonials">
         <div class="container">
            <div class="section-title">
            <span>Happy Subscribers</span>
               <h2>Testimonials</h2>
            </div>
            <div class="testimonial-wrap owl-theme owl-carousel">
               <div class="single-testimonial">
                  <p>"We requested 100 laptops with the same configuration and installed basic softwares.We really impressed with how they delivered on time with our requirements."</p>
                  <h3> Navin Kumar</h3>
                  <span>Techsoft Pvt Ltd</span>
               </div>
               <div class="single-testimonial">
                  <p>“Amazing service. You guys know how to do business.You were awesome in processing my request on priority and I'm really impressed with how you manage and notify your customers. The laptop was also of good quality and I got a good deal too. Thank you guys. Cheers.”</p>
                  <h3>S.A.Pavithra</h3>
               </div>
               <div class="single-testimonial">
                  <p>"Comtal Laptop Rentals team was amazing. I came in with a last minute requirement for an event in my college. They went beyond my expectations and delivered the 40 laptops I required for the intercollegiate event. Thank you very much and I really appreciate your amazing service."</p>           
                  <h3>Anjali Sharma</h3>
               </div>
            </div>
         </div>
      </section>


      <section class="rs-section rs-home-section-six">
        <div class="container">
            <div class="rs-section-head-wrapper">
                <h2 class="rs-section-head has-underline">
                Laptop or desktop? We've got it! Rent with us!

                </h2>
            </div>
            <div class="rs-section-main-content">
                <div class="rs-post-requirement-section-rig">
                    <span class="rs-post-requirement-btn-wrap">
                <span class="rs-primary-btn rs-post-requirement-btn" target="_blank" rel="noopener">Get 24/7 Support</span>
              </span>
                    <p class="rs-info-text">OR simply call us at <a href="tel:+91 90425 74600" class="region-contact-number">+91 90425 74600</a></p>
                </div>
            </div>



  <div class="levels-row row location-t-p-1">
   <div class="resources-col col-md-4">
      <div class="article-summary-block">
         <div class="links-summary-block">
            <div class="block-title">North Chennai</div>
            <div class="small-resource-list-wrapper w-dyn-list">
               <div role="list" class="small-resource-list w-dyn-items">
                  <div role="listitem" class="small-resource-item w-dyn-item">
                     <a class="resource-link-block w-inline-block">
                        <img  src="./assets/images/location.svg"  class="small-link-icon" alt="Serach Location"/>
                        <div class="resource-link-title">Tiruvottiyur</div>
                     </a>
                  </div>
                  <div role="listitem" class="small-resource-item w-dyn-item">
                     <a class="resource-link-block w-inline-block">
                        <img  src="./assets/images/location.svg"  class="small-link-icon" alt="Serach Location"/>
                        <div class="resource-link-title">Manali</div>
                     </a>
                  </div>
                  <div role="listitem" class="small-resource-item w-dyn-item">
                     <a class="resource-link-block w-inline-block">
                        <img  src="./assets/images/location.svg"  class="small-link-icon" alt="Serach Location"/>
                        <div class="resource-link-title">Madhavaram</div>
                     </a>
                  </div>
                  <div role="listitem" class="small-resource-item w-dyn-item">
                     <a class="resource-link-block w-inline-block">
                        <img  src="./assets/images/location.svg"  class="small-link-icon" alt="Serach Location"/>
                        <div class="resource-link-title">Tondiarpet</div>
                     </a>
                  </div>
                  <div role="listitem" class="small-resource-item w-dyn-item">
                     <a class="resource-link-block w-inline-block">
                        <img  src="./assets/images/location.svg"  class="small-link-icon" alt="Serach Location"/>
                        <div class="resource-link-title">Royapuram</div>
                     </a>
                  </div>
               </div> 
            </div>
         </div>
         <div class="links-summary-block bottom">
            <a href="https://goo.gl/maps/Ebptkb5XiaAm7Snj9" target="_blank" class="resource-link-block view-all w-inline-block">
               <div class="resource-link-title">Live Location</div>
            </a>
         </div>
      </div>
   </div>
   <div class="resources-col col-md-4">
      <div class="article-summary-block">
         <div class="links-summary-block">
            <div class="block-title">Central Chennai</div>
            <div class="small-resource-list-wrapper w-dyn-list">
               <div role="list" class="small-resource-list w-dyn-items">
                  <div role="listitem" class="small-resource-item w-dyn-item">
                     <a class="resource-link-block w-inline-block">
                        <img  src="./assets/images/location.svg"  class="small-link-icon" alt="Serach Location"/>
                        <div class="resource-link-title">Thiru-VI-Ka Nagar</div>
                     </a>
                  </div>
                  <div role="listitem" class="small-resource-item w-dyn-item">
                     <a class="resource-link-block w-inline-block">
                        <img  src="./assets/images/location.svg"  class="small-link-icon" alt="Serach Location"/>
                        <div class="resource-link-title">Ambattur</div>
                     </a>
                  </div>
                  <div role="listitem" class="small-resource-item w-dyn-item">
                     <a class="resource-link-block w-inline-block">
                        <img  src="./assets/images/location.svg"  class="small-link-icon" alt="Serach Location"/>
                        <div class="resource-link-title">Anna Nagar</div>
                     </a>
                  </div>
                  <div role="listitem" class="small-resource-item w-dyn-item">
                     <a class="resource-link-block w-inline-block">
                        <img  src="./assets/images/location.svg"  class="small-link-icon" alt="Serach Location"/>
                        <div class="resource-link-title">Teynampet</div>
                     </a>
                  </div>
                  <div role="listitem" class="small-resource-item w-dyn-item">
                     <a class="resource-link-block w-inline-block">
                        <img  src="./assets/images/location.svg"  class="small-link-icon" alt="Serach Location"/>
                        <div class="resource-link-title">Kodambakkam</div>
                     </a>
                  </div>
               </div>
            </div>
         </div>
         <div class="links-summary-block bottom">
         <a href="https://goo.gl/maps/Ebptkb5XiaAm7Snj9" target="_blank" class="resource-link-block view-all w-inline-block">
               <div class="resource-link-title">Live Location</div>
            </a>
         </div>
      </div>
   </div>
   <div class="resources-col col-md-4">
      <div class="article-summary-block">
         <div class="links-summary-block">
            <div class="block-title">South Chennai</div>
            <div class="small-resource-list-wrapper w-dyn-list">
               <div role="list" class="small-resource-list w-dyn-items">
                  <div role="listitem" class="small-resource-item w-dyn-item">
                     <a class="resource-link-block w-inline-block">
                        <img  src="./assets/images/location.svg"  class="small-link-icon" alt="Serach Location"/>
                        <div class="resource-link-title">Valasaravakkam</div>
                     </a>
                  </div>
                  <div role="listitem" class="small-resource-item w-dyn-item">
                     <a class="resource-link-block w-inline-block">
                        <img  src="./assets/images/location.svg"  class="small-link-icon" alt="Serach Location"/>
                        <div class="resource-link-title">Alandur</div>
                     </a>
                  </div>
                  <div role="listitem" class="small-resource-item w-dyn-item">
                     <a class="resource-link-block w-inline-block">
                        <img  src="./assets/images/location.svg"  class="small-link-icon" alt="Serach Location"/>
                        <div class="resource-link-title">Guindy</div>
                     </a>
                  </div>
                  <div role="listitem" class="small-resource-item w-dyn-item">
                     <a class="resource-link-block w-inline-block">
                        <img  src="./assets/images/location.svg"  class="small-link-icon" alt="Serach Location"/>
                        <div class="resource-link-title">Adyar</div>
                     </a>
                  </div>
                  <div role="listitem" class="small-resource-item w-dyn-item">
                     <a class="resource-link-block w-inline-block">
                        <img  src="./assets/images/location.svg"  class="small-link-icon" alt="Serach Location"/>
                        <div class="resource-link-title">Sholinganallur</div>
                     </a>
                  </div>
               </div>
            </div>
         </div>
         <div class="links-summary-block bottom">
         <a href="https://goo.gl/maps/Ebptkb5XiaAm7Snj9" target="_blank" class="resource-link-block view-all w-inline-block">
               <div class="resource-link-title">Live Location</div>
            </a>
         </div>
      </div>
   </div>
   <p class="location-hidden">Adyar, Alapakkam, Alwarpet, Ambattur, Aminjikarai, Anna Nagar, Annanur, Arumbakkam, Ashok Nagar, Besant Nagar, Basin Bridge, Chepauk, Chetput, Chitlapakkam, Choolaimedu, Chrompet, Egmore, Ennore, Fort St. George, George Town, Gopalapuram, Guindy, Gerugambakkam, IIT Madras, Jafferkhanpet, Karapakkam, Kazhipattur, K.K. Nagar, Kilpauk, Kodambakkam, Kodungaiyur, Kolathur, Korattur, Korukkupet, Kottivakkam, Kotturpuram, Kottur, Kovilambakkam, Madhavaram, Madhavaram Milk Colony, Madipakkam, Madambakkam, Manapakkam, Mandaveli, Mangadu, Mathur, Medavakkam, Meenambakkam, MGR Nagar, Mogappair, MKB Nagar, Mount Road, Moolakadai, Moulivakkam, Mugalivakkam, Mudichur, Mylapore, Nandanam, Nemilichery, Nolambur, Noombal, Otteri, Padi, Pakkam, Palavakkam, Park Town, Pattabiram, Pattaravakkam, Perambur, Peravallur, Perumbakkam, Perungudi, Pozhichalur, Poonamallee, Porur, Pudupet, Pulianthope, Raj Bhavan, Ramavaram, Red Hills, Royapuram, Saidapet, Saligramam, Sembakkam, Selaiyur, Shenoy Nagar, Sholavaram, Sithalapakkam, Tambaram, Teynampet, Tharamani, T. Nagar, Thirumangalam, Thiruninravur, Tondiarpet, United India Colony, Vandalur, Vadapalani, Vallalar Nagar, Vanagaram, Velachery, Villivakkam, West Mambalam</p>
</div>



        </div>
    </section>



</div>
    
);
}
}
export default WhatWeDo;