import React from 'react';

class Services extends React.Component{
render()
{
return (
<div>
    
<section class="facility-area facility-area-three pt-80 pb-65" id="About">
         <div class="container">
         <div class="section-title">
                <span>About Comtal</span> 
               <h2>Welcome to Comtal Technologies</h2>
               <p>At Comtal Technologies, we believe getting the best technology
shouldn't come with the stress of buying, maintaining, and
upgrading. That's why we've been a trusted rental partner since 2019
providing reliable, hassle-free access to workstations, laptops,
desktops, and all IT equipment needs for over 25 companies!
</p>
            </div>
        
         </div>
      </section>

      <section class="feature section pb-5 ">
   <div class="container">
      <div class="row justify-content-between align-items-center">
        
      <div class="col-lg-6">
            <div class="image-content image-content-1 aos-init aos-animate" data-aos="fade-right">
              <img src="./assets/images/Coder.svg" alt="Slider"/>
            </div>
         </div>
      <div class="col-lg-6">
            <div class="feature-content">
               <h2>Why Rent with Comtal Technologies?</h2>
               <p class="desc">Buying new technology can be expensive, time-consuming, and leave
you stuck with outdated equipment when the next big thing comes
along. Renting with Comtal Technologies offers a smarter alternative:
</p>
            </div>
         </div>
         
      </div>
   </div>
</section>

<section class="feature services style3 section pt-5 mb-80 rpt-20">
   <div class="container">
   <div class="single-head">
  <div class="row">
    <div class="col-lg-4 col-md-6 col-12">
      <div class="single-service wow fadeInUp" data-wow-delay=".2s" >
        <span class="serial">01</span>
        <h3>
          <a>Cost-Effective</a>
        </h3>
        <p>Save significant capital by renting the equipment
you need exactly when you need it, without the burden of long-term
ownership costs.</p>
        
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-12">
      <div class="single-service wow fadeInUp" data-wow-delay=".4s" >
        <span class="serial">02</span>
        <h3>
          <a>Hassle-Free</a>
        </h3>
        <p>Say goodbye to installation headaches, maintenance
worries, and upgrade fatigue. We handle everything from setup to
teardown, leaving you free to focus on what matters most.</p>
        
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-12">
      <div class="single-service wow fadeInUp" data-wow-delay=".6s" >
        <span class="serial">03</span>
        <h3>
          <a>Flexibility</a>
        </h3>
        <p>Get the perfect tech fit for any project or event, scaling up or down your equipment needs as your demands change.</p>
        
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-12">
      <div class="single-service wow fadeInUp" data-wow-delay=".2s" >
        <span class="serial">04</span>
        <h3>
          <a>Assured Quality</a>
        </h3>
        <p>We only offer top-tier equipment from leading brands, rigorously tested and maintained to ensure optimal performance.</p>
        
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-12">
      <div class="single-service wow fadeInUp" data-wow-delay=".4s" >
        <span class="serial">05</span>
        <h3>
          <a>Professional Service</a>
        </h3>
        <p>Our experienced engineers are here to guide you every step of the way, from choosing the right equipment to installation and ongoing support.</p>
        
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-12">
      <div class="single-service wow fadeInUp" data-wow-delay=".6s" >
        <span class="serial">06</span>
        <h3>
          <a>UX &amp; UI Design</a>
        </h3>
        <p>We dejoy working with discerning clients, people for whom qualuty, service, integrity &amp; aesthetics.</p>
        
      </div>
    </div>
  </div>
</div>
      <div class="row justify-content-between align-items-center">
         <div class="col-lg-6">
            <div class="feature-content tp-20">
               <h2>What do we do?</h2>
               <p class="desc">Say goodbye to tech headaches and hello to seamless solutions.</p>
               <p class="desc">At
Comtal Technologies , we're not just in the business of renting
laptops and desktops – we're your one-stop shop for hassle-free tech
power on demand. </p>
            </div>
         </div>
         
         <div class="col-lg-6">
            <div class="image-content text-center aos-init aos-animate" data-aos="fade-right">
              <img src="./assets/images/Launch.svg" alt="Slider"/>
            </div>
         </div>
      </div>
   </div>
</section>

</div>
    
);
}
}
export default Services;