import React from 'react';

class Topheader extends React.Component{
render()
{
return (
<div>
<header class="header-area fixed-top">
         <div class="top-header-area">
            <div class="container">
               <div class="row align-items-center">
                  <div class="col-lg-6">
                     <div class="header-content-left">
                        <p>Welcome To Comtal Laptop Rentals</p>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <ul class="header-content-right">
                        <li>
                           <a href="#">
                           <i class="bx bx-envelope"></i>
                           Email: <span class="__cf_email__" data-cfemail="472e2921280736212e3f6924282a">[email&#160;protected]</span>
                           </a>
                        </li>
                        <li>
                           <a href="tel:8098081991">
                           <i class="bx bx-phone-call"></i>
                           Call Us For Inquiry: +91 8098081991
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <div class="nav-area">
            <div class="navbar-area">
               <div class="mobile-nav">
                  <a href="index.html" class="logo">
                  <img src="img/logo.png" alt="Logo" />
                  </a>
               </div>
               <div class="main-nav">
                  <nav class="navbar navbar-expand-md navbar-light">
                     <div class="container">
                        <a class="navbar-brand" href="index.html">
                        <img src="img/logo.png" class="logo" alt="Logo" />
                        </a>
                        <div class="collapse mean-menu" id="navbarSupportedContent">
                           <ul class="navbar-nav m-auto">
                              <li class="nav-item">
                                 <a href="#" class="active">Home</a>
                              </li>
                              <li class="nav-item">
                                 <a href="about.html" class="nav-link">About Comtal</a>
                              </li>                              
                              <li class="nav-item">
                                 <a href="#" class="nav-link">Services</a>
                              </li>
                              <li class="nav-item">
                                 <a href="#" class="nav-link"> Why Rent?</a>                                 
                              </li>
                              <li class="nav-item">
                                 <a href="contact.html" class="nav-link">Contact Us</a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </nav>
               </div>
            </div>
         </div>
      </header>

</div>
    
);
}
}
export default Topheader;