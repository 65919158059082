import React from 'react';


class Header extends React.Component{
   constructor(props) {
      super(props);
      this.state = {
        activeLink: 'home'
      };
    }
  
    handleLinkClick = (link) => {
      this.setState({ activeLink: link });
    };
render()

{
   const { activeLink } = this.state;

 
return (
<div>
<header class="header-area fixed-top">
         <div class="top-header-area">
            <div class="container">
               <div class="row align-items-center">
                  <div class="col-lg-6">
                     <div class="header-content-left">
                        <p>Welcome To  Comtal Technologies</p>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <ul class="header-content-right">
                        <li>
                           <a href="#">
                           <i class="bx bx-envelope"></i>
                            <span class="__cf_email__" data-cfemail="">helpdesk@comtal.in</span>
                           </a>
                        </li>
                        <li>
                           <a href="tel:90425 74600">
                           <i class="bx bx-phone-call"></i>
                           +91  9159153450
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <div class="nav-area">
            <div class="navbar-area">
               <div class="mobile-nav">
                  <a href="#" class="logo">
                     <img  src="./assets/images/logo.svg" alt="Logo"/>
                  </a>
               </div>
               <div class="main-nav">
                  <nav class="navbar navbar-expand-md navbar-light">
                     <div class="container">
                        <a class="navbar-brand" href="#">
                        <img  src="./assets/images/logo.svg" alt="Logo" style={{width:"185px"}}/>
                        </a>
                        <div id="mean-menu-top">
                        <div class="mean-menu">
                           <ul class="navbar-nav m-auto">
                           <li class="nav-item">
                           <a href="#Home" className={`nav-link ${ activeLink === 'home' ? 'active' : ''}`} onClick={() => this.handleLinkClick('home')}>
                              Home
                            </a>
                            </li>
                              <li class="nav-item">
                              <a
                              href="#About"
                              className={`nav-link ${
                                activeLink === 'about' ? 'active' : ''
                              }`}
                              onClick={() => this.handleLinkClick('about')}
                            >
                              About Comtal
                            </a>
                              </li>                              
                              <li class="nav-item">
                                 <a href="#Services"   className={`nav-link ${
                                activeLink === 'Services' ? 'active' : ''
                              }`}
                              onClick={() => this.handleLinkClick('Services')}>Services</a>
                              </li>
                              <li class="nav-item">
                                 <a href="#Testimonials"className={`nav-link ${
                                activeLink === 'Testimonials' ? 'active' : ''
                              }`}
                              onClick={() => this.handleLinkClick('Testimonials')}> Testimonials</a>                                 
                              </li>
                              <li class="nav-item">
                                 <a href="#Contact" className={`nav-link ${
                                activeLink === 'Contact' ? 'active' : ''
                              }`}
                              onClick={() => this.handleLinkClick('Contact')}>Contact Us</a>
                              </li>
                           </ul>
                        </div>
                        </div>
                     </div>
                  </nav>
               </div>
            </div>
         </div>
      </header>
</div>
    
);
}
}
export default Header;