import React from 'react';
import Header from './Header';
import Slider from './Slider';
import About from './About';
import WhatWeDo from './WhatWeDo';
import Services from './Services';
import Testimonials from './Testimonials';
import Footer from './Footer';
import ChatBot from './ChatBot';


class Home extends React.Component{
render()
{
return (
<div id="wrapper">

    <Header />
    <Slider />
    <About />
    <WhatWeDo />
    {/* <Services /> */}
    <Testimonials/>
    <Footer />

    {/* <ChatBot /> */}


</div>
    
);
}
}
export default Home;