import React from 'react';

class Services extends React.Component{
render()
{
return (
<div>
    
<section class="facility-area facility-area-three pt-100 pb-70" id="Services">
         <div class="container">
         <div class="section-title">
               <span>What We Do</span>
               <h2>Welcome To Comtal</h2>
               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus</p>
            </div>
            <div class="row">
               <div class="col-lg-3 col-sm-6">
                  <div class="single-facility">
                     <img  src="./assets/images/desktop.png" alt="Computer Rental"/>
                     <h3>Computer Rental</h3>
                     <p>Do you need PC or Perhaps MAC Computers for rent? Is your need specifically for Computer, that to from the same manufacturer or model number.</p>
                     
                  </div>
               </div>
               <div class="col-lg-3 col-sm-6">
                  <div class="single-facility">
                     <img  src="./assets/images/laptop.png" alt="Laptop Rental"/>
                     <h3>Laptop Rental</h3>
                     <p>Don’t need to spend an entire fortune in buying laptops for professional and Corporate, such as coaching sessions, conferences, temporary & more.</p>
                    
                  </div>
               </div>
               <div class="col-lg-3 col-sm-6">
                  <div class="single-facility">
                  <img  src="./assets/images/server-rental.png" alt="Server Rental"/>
                     <h3>Server Rental</h3>
                     <p>Never let your network down by renting a server from our large range of dedicated servers, available on short to long term rental, from leading brands.</p>
                     
                  </div>
               </div>
               <div class="col-lg-3 col-sm-6">
                  <div class="single-facility">
                  <img  src="./assets/images/printer.png" alt="Printer Renta"/>
                     <h3>Printer Rental</h3>
                     <p>Never let your network down by renting a server from our large range of dedicated servers, available on short to long term rental, from leading brands.</p>
                     
                  </div>
               </div>
            </div>
         </div>
      </section>

</div>
    
);
}
}
export default Services;