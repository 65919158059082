import React from 'react';

class Slider extends React.Component{
render()
{
return (
<div>

<section class="banner-area-six jarallax" data-jarallax='{"speed": 0.3}'>
         <div class="d-table">
            <div class="d-table-cell">
               <div class="container">
                  <div class="row align-items-center">
                     <div class="col-lg-6">
                        <div class="banner-text">
                           <span>Comtal Technologies</span>
                           <h1>Your Laptop and Desktop Rental Partner!</h1>
                           <p>with 100% percentage hardware support</p>
                           <div class="banner-btn">
                              <a class="default-btn" href="#Contact">
                              Contact Us
                              </a>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="repairs-img">
                        <img  src="./assets/images/2.jpg" alt="Slider"/>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

</div>
    
);
}
}
export default Slider;